<template>
    <div class="sell">
        <div class="head">
            <span class="iconfont icon-a-payicon101_zhuanqu_huaban1fuben60"></span>
            <span class="title">SELL卖出</span>
        </div>

        <div class="details">
            <van-cell style="padding-left: 0;">
                <div class="split">
                    <p class="left">是否拆分</p>
                    <div class="right">
                        <span @click="isSplit = 1" :class="{ isSplit: isSplit === 1 }">拆分</span>
                        <span @click="isSplit = 0" :class="{ isSplit: isSplit === 0 }">不拆分</span>
                    </div>
                </div>
            </van-cell>

            <p class="item">选择收款方式</p>
            <van-cell :title="title" is-link arrow-direction="down" @click="onSelect" />
            <van-action-sheet v-model:show="show" title="选择收款方式">
                <div class="content">
                    <van-checkbox-group v-model="paytype" @change="getTitle">
                        <van-cell clickable>
                            <template #title>
                                <img :src="require('@/assets/img/pay_icon_1.png')" />
                                <span>银行卡</span>
                            </template>
                            <template #right-icon>
                                <van-checkbox name="1" :disabled="isPay_bank" />
                            </template>
                        </van-cell>

                        <van-cell clickable>
                            <template #title>
                                <img :src="require('@/assets/img/pay_icon_3.png')" />
                                <span>支付宝</span>
                            </template>
                            <template #right-icon>
                                <van-checkbox name="3" :disabled="isPay_alipay" />
                            </template>
                        </van-cell>

                        <van-cell clickable>
                            <template #title>
                                <img :src="require('@/assets/img/pay_icon_2.png')" />
                                <span>微信</span>
                            </template>
                            <template #right-icon>
                                <van-checkbox name="2" :disabled="isPay_wechat" />
                            </template>
                        </van-cell>
                    </van-checkbox-group>
                </div>
            </van-action-sheet>

            <van-cell style="padding-left: 0;">
                <p class="item">可售余额： {{ userInfo.ACC_BAL_SALE }}</p>
            </van-cell>

            <p class="item">出售总数</p>
            <van-field v-model="amt" placeholder="请输入个数" />

            <p class="item">备注：（选填）</p>
            <van-field v-model="remark" placeholder="请输入备注" />
        </div>

        <van-button class="btn" type="primary" block round @click="sell">确定</van-button>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getBankList, IWannaSell } from '@/api'
import { Dialog, Toast } from 'vant'

export default {
    setup() {
        const state = reactive({
            title: '付款方式',
            show: false,
            isNoCard: false,

            userInfo: {},
            isPay_bank: true,
            isPay_wechat: true,
            isPay_alipay: true,
            paytype: [], // 收款类型
            amt: '', // 出售数量
            remark: '', // 备注
            isSplit: 0 // 是否拆分 0否1是
        })

        const router = useRouter()
        const store = useStore()

        state.userInfo = store.state.userInfo

        onMounted(() => {
            // 1银行卡 2微信 3支付宝
            getBankList().then(res => {
                let data = res.data

                if (!data.length) {
                    state.isNoCard = true
                    return
                }

                data.forEach(item => {
                    if (item.BANK_TYPE === '1') {
                        state.isPay_bank = false
                    }
                    if (item.BANK_TYPE === '2') {
                        state.isPay_wechat = false
                    }
                    if (item.BANK_TYPE === '3') {
                        state.isPay_alipay = false
                    }
                })

                if (!state.isPay_wechat) state.paytype.push('2')
                if (!state.isPay_alipay) state.paytype.push('3')
                if (!state.isPay_bank) state.paytype.push('1')

                getTitle()
            })
        })

        const getTitle = () => {
            let nameArr = []
            state.paytype.includes('1') && nameArr.push('银行卡')
            state.paytype.includes('3') && nameArr.push('支付宝')
            state.paytype.includes('2') && nameArr.push('微信')
            state.title = nameArr.join(', ')
        }

        const onSelect = () => {
            if (state.isNoCard) {
                Dialog.confirm({
                    message: '是否添加收款方式？'
                })
                    .then(() => {
                        router.push('/BankInformation')
                    })
                    .catch(() => {
                        console.log('弹窗点击取消了')
                    })
            } else {
                state.show = true
            }
        }

        const sell = () => {
            Dialog.confirm({
                messageAlign: 'left',
                title: '温馨提示',
                message: '亲爱的卖家您好，交易中当买家下单购买并点击了【我已转账】时，请您立即查账是否收到货款，如果没有收到请立即点【暂停打币】申请客服介入，否则10分钟后，系统会自动打币给买家，造成的损失由您个人承担。'
            })
                .then(() => {
                    IWannaSell({
                        paytype: state.paytype.join(''),
                        amt: state.amt,
                        remark: state.remark,
                        isSplit: state.isSplit
                    }).then(res => {
                        if (res.status) {
                            Toast.success('卖出成功！')
                            router.push('/Home')
                        } else {
                            Toast.fail(res.message)
                        }
                    })
                })
                .catch(() => {})
        }

        return {
            ...toRefs(state),
            router,
            onSelect,
            getTitle,
            sell
        }
    }
}
</script>

<style lang="scss" scoped>
.sell {
    background: $background;

    .head {
        width: 100%;
        height: h(214);
        text-align: center;
        line-height: h(150);
        font-size: 30px;
        color: #ffffff;
        background: #3366ee;
        background-image: url('~@/assets/img/help-bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-bottom-left-radius: 20%;
        border-bottom-right-radius: 20%;

        .icon-a-payicon101_zhuanqu_huaban1fuben60 {
            font-size: 40px;
            margin-right: w(12);
        }

        .title {
            letter-spacing: 4px;
        }
    }

    .details {
        margin: h(-90) auto 0;
        width: w(346);
        background: #ffffff;
        border-radius: 8px;
        box-sizing: border-box;
        padding: h(15) w(11);
        color: #333333;

        .split {
            line-height: h(60);
            display: flex;
            justify-content: space-between;

            .right {
                span {
                    background: rgba(109, 136, 251, 0.1);
                    color: #333333;
                    border-radius: 37px;
                    padding: 10px 12px;
                    margin-left: w(12);
                }

                span.isSplit {
                    background: #3366ee;
                    color: #ffffff;
                }
            }
        }

        .content {
            padding: 16px 16px h(30);
            text-align: left;

            img {
                width: h(45);
                height: h(45);
                margin-right: w(10);
                vertical-align: middle;
            }

            span {
                font-size: 14px;
                line-height: 109.48%;
            }
        }

        .item {
            margin: h(15) 0 h(10);
            font-size: 14px;
            line-height: 18px;
        }
    }

    .btn {
        width: w(346);
        height: h(45);
        margin: h(40) auto 0;
    }
}
</style>
